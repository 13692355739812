<template>
  <div class="flex w-full items-start justify-between">
    <div class="review-card-header mb-4 flex items-center justify-start">
      <div class="avatar relative mr-4 h-10 w-10">
        <div
          v-if="!review.unknownAuthor"
          class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center overflow-hidden rounded-full dark:bg-gray-600"
          :class="avatarCircleColor"
        >
          {{ initial }}
        </div>
        <div v-else class="absolute left-0 top-0 h-10 w-10 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
          <svg class="absolute -left-1 h-12 w-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path>
          </svg>
        </div>
      </div>
      <div class="bloc-details">
        <div class="display-name mb-1">
          <h3 class="text-sm" :class="review.unknownAuthor && 'text-gray-400'">{{ review.author }}</h3>
        </div>
        <div class="sub-block-details flex h-full items-center justify-start">
          <span v-if="review.tag" class="mr-2 rounded-full bg-gray-200 px-2.5 py-0.5 text-xs font-medium text-textSecondary">
            {{ reviewTag }}
          </span>
          <div class="review-date flex h-full items-center">
            <p class="text-xs text-textLight">{{ review.reviewDisplayDate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue';
import _ from 'lodash';

const props = defineProps({
  // @example:
  // { id:, author:, content:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply: ...}
  review: {
    type: Object,
    required: true,
  },
});

const avatarCircleColor = ref('');

onMounted(() => {
  avatarCircleColor.value = _.sample(['bg-primary text-white', 'bg-red-200 text-red-dark', 'bg-info text-blue-dark', 'bg-warning text-yellow-dark']);
});

const reviewTag = computed(() => {
  return _.capitalize(props.review.tag);
});

const initial = computed(() => {
  return _.capitalize(props.review.author[0]);
});
</script>
